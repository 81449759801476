<template>
  <div class="container lead-ads-historicc">
    <div>
      <BaseHeader title="Histórico de LeadAds" :navigation="navigation"></BaseHeader>
      <div class="box-total justify-content-center mb-3">
        <div>
          <p class="total-vendas">
            Total de leads capturados:
            <span>{{ totalIntegrations }}</span>
          </p>
        </div>
        <div></div>
      </div>

      <div class="p-0 d-flex" style="gap: 15px;">
        <b-dropdown
          text="Fanpage"
          variant="filter"
          toggleText=""
          id="drop-fanpages"
        >
          <template #button-content>
            <div
              style="
                display: flex;
                align-items: center;
                color: var(--greenn);
                width: 216px;
                position: relative;
              "
            >
              <img
                :src="require('@/assets/icons/fanpageGrenn.svg')"
                alt="Fanpage"
                class="mr-1"
              />
              <span style="color: inherit; max-width: 165px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ getNameFanpageFiltered }}</span>
              
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" style="position: absolute; right: 4px;
              top: 5px; stroke: var(--greenn);" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
            </div>
          </template>
          <b-dropdown-item
            class="drop-item"
            v-for="dataFanpage of dataFanpages"
            :key="dataFanpage.id"
            :active="activeFanpageSelected(dataFanpage.id)"
            @click="filterForFanpage(dataFanpage)"
          >
            <div
              :style="{
                'background-image': `url(${dataFanpage.fb_link_img})`, 'background-size': 'cover', 'border-radius': '5px'
              }"
              class="img-page"
            ></div>
            <span>
              {{ dataFanpage.fb_fanpage_title }}
            </span>
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown
          text="Fanpage"
          variant="filter"
          toggleText=""
          :class="getClassToDisabeldBtnFilter"
          :disabled="getClassToDisabeldBtnFilter !== ''"
        >
          <template v-if="integrationId && getNameFormFiltered" #button-content>
            <div
              style="
                display: flex;
                align-items: center;
                gap: 4px;
                color: var(--greenn);
                width: 216px;
                position: relative;
            ">
              <span style="color: inherit; max-width: 65px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                {{ getNameFormFiltered.form_fb }}
              </span>
              <img :src="facebookBlack" alt="Facebook" class="change-color m-0" />
              <img :src="lightningGreen" alt="Integração" class="m-0" />
              <img :src="gDigital" alt="Integração" class="change-color m-0" />
              <span style="color: inherit; max-width: 65px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                {{ getNameFormFiltered.form_g }}
              </span>
            </div>
          </template>

          <template v-else #button-content>
            <div
            style="
                display: flex;
                align-items: center;
                color: var(--greenn);
                width: 216px;
                position: relative;
              "
            >
              <img :src="lightningGreen" alt="Integrações" class="mr-1" />
              Selecione a integração
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" style="position: absolute; right: 4px;
              top: 5px; stroke: var(--greenn);" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
            </div>
          </template>

          <b-dropdown-item
            href="#"
            v-for="(dataForm, index) in dataForms"
            :key="index"
            :active="
              activeFormIntegrationSelected(
                dataForm.fanpageFormIntegration.id
              )
            "
            @click="filterForForm(dataForm)"
            class="integration-option"
            style="gap: 5px;"
          > 
            <span>{{ dataForm.fb_form_title }}</span>
            <img :src="facebookBlack" alt="Facebook" class="m-0" />
            <img :src="lightningGreen" alt="Integração" class="m-0" />
            <img :src="gDigital" alt="Integração" class="m-0" />
            <span>{{ dataForm.form_g_digital }}</span>
          </b-dropdown-item>
        </b-dropdown>

        <b-button
          variant="filter"
          @click="clearFilter"
          v-if="fanpageId"
          >Limpar filtros</b-button
        >
      </div>

      <div class="content-table">
        <b-table
          :borderless="true"
          :items="items"
          :fields="fields"
          :fixed="false"
          show-empty
        >
          <template #empty="scope">
            <b-row class="justify-content-center style" v-if="!loadingTable">
              <p class="nao-encontrado">Nenhum Lead Ads foi encontrada</p>
            </b-row>
            <div v-else class="w-100">
              <div v-for="n in 4" :key="n" class="shine mb-2 py-3 w-100">
              </div>
            </div>
          </template>

          <template #cell(status)="row">
            <div
              class="status-lead"
              :class="{ ['status-' + row.item.status]: true }"
              :title="getTitleStatus(row.item.status)"
            ></div>
          </template>

          <template #cell(id)="row"> # {{ row.item.id }} </template>

          <template #cell(integrations)="row">
            <div class="d-flex align-items-center">
              <span
                class="d-inline-block text-truncate"
                style="max-width: 150px"
                :title="row.item.integration"
                >{{ row.item.integration }}</span
              >
              <img :src="facebookBlack" alt="Facebook" />
              <img :src="lightningGreen" alt="Integração" />
              <img :src="gDigital" alt="Integração" />
              <span
                class="d-inline-block text-truncate"
                style="max-width: 150px"
                :title="row.item.form_g_digital"
                >{{ row.item.form_g_digital }}</span
              >
            </div>
          </template>

          <template #cell(actions)="row">
            <svg 
              v-if="row.item.status != 'error' && row.item.status != 'token_error'" @click="row.item.status == 'success' ? getLeadgen(row.item) : ''" :style="row.item.status == 'success' ? 'cursor: pointer;' : `filter: grayscale(1); cursor: not-allowed;`, loadingLead == row.item.id ? 'opacity: 0.5' : ''" xmlns="http://www.w3.org/2000/svg" style="transition: all 0.3s; cursor: pointer;" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#4ea934" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
            <svg :style="loadingReprocess == row.item.id ? 'opacity: 0.5' : ''" v-else @click="reprocessLead(row.item)" xmlns="http://www.w3.org/2000/svg" style="transition: all 0.3s; cursor: pointer;" width="24" height="24" fill="#4ea934" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
          </template>
        </b-table>

        <ModalViewLeadgen
          :idModal="idModalviewLeadgen"
          :leadgen="leadgen"
          :updatedItem="updatedItem"
        />

        <Paginate
          v-if="totalIntegrations > 8"
          :totalPages="totalPages"
          :activePage="currentPage"
          @to-page="dataLeads"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader";
import ModalViewLeadgen from "./ModalViewLeadgen.vue";
import Paginate from "@/components/Paginate";

import LeadAdService from "@/services/resources/LeadAdService.js";
const LeadAdApiService = LeadAdService.build();
import { mapMutations } from "vuex";

import moment from "moment";

export default {
  name: "HistoricForLeadAd",
  components: {
    BaseHeader,
    Paginate,
    ModalViewLeadgen,
  },
  data: () => ({
    loadingTable: false,
    loadingLead: false,
    loadingReprocess: false,
    lightningGreen: require("@/assets/icons/lightningGreen.svg"),
    facebookBlack: require("@/assets/icons/facebookBlack.svg"),
    gDigital: require("@/assets/icons/gDigital.svg"),
    fields: [
      { key: "status", label: "" },
      "Capturado em",
      { key: "integrations", label: "Integração" },
      "Fanpage",
      { key: "actions", label: "" },
    ],
    updatedItem: '',
    items: [],
    fanpageId: null,
    integrationId: null,
    numberPage: 0,
    currentPage: 1,
    totalPages: 0,
    dataFanpages: [],
    dataForms: [],
    totalIntegrations: 0,
    idModalviewLeadgen: "modal-view-leadgen",
    leadgen: {},
    nameIntegrationFiltered: {
      form_g: "",
      form_fb: "",
    },
    navigation: [
      { link: "Aplicativos", to: "/dynamicRoute/apps" },
      { link: "LeadAds", to: "/dynamicRoute/apps/lead-ads" },
      { link: "Histórico de LeadAds", to: "/dynamicRoute/apps/lead-ads/leadgen/" },
    ],
  }),
  watch: {
    $route: function (to) {
      let params = to.params;
      if (!params.idFanpage && !params.idIntegration) {
        this.fanpageId = null;
        this.integrationId = null;
        this.dataForms = [];
        this.dataLeads();
      }
    },
  },
  computed: {
    activeFanpageSelected: function () {
      return (id) => {
        return +this.fanpageId === +id;
      };
    },
    activeFormIntegrationSelected: function () {
      return (id) => {
        return +this.integrationId === +id;
      };
    },
    getUrlToIndexFanpage: function () {
      if (this.fanpageId) {
        return `/${this.$store.getters.user.user.tenant_subdomain}/apps/lead-ads/fanpage/` + this.fanpageId;
      }
      return `/${this.$store.getters.user.user.tenant_subdomain}/apps/lead-ads`;
    },
    getNameToUrlFanpage: function () {
      if (this.fanpageId) {
        return "Fanpage";
      }
      return "Listagem de Fanpages";
    },
    activeBtnViewHistoric: function () {
      return (status) => {
        switch (status) {
          case "success":
          case "token_error":
          case "error":
            return true;
        }
      };
    },
    getClassToDisabeldBtnFilter: function () {
      if (!this.fanpageId) {
        return "btn-filter-disabled";
      }
      return "";
    },
    getTitleStatus: function () {
      return (status) => {
        switch (status) {
          case "success":
            return "Sucesso";
          case "token_error":
            return "Erro de token";
          case "error":
            return "Erro";
          default:
            return "Em processamento";
        }
      };
    },
    getNameFanpageFiltered() {
      if (this.fanpageId) {
        let data = this.dataFanpages.find(
          (item) => +item.id === +this.fanpageId
        );
        if (data) {
          return data.fb_fanpage_title;
        }
      }
      return "Selecione uma FanPage";
    },
    getNameFormFiltered() {
      if (this.integrationId) {
        let data = this.dataForms.find(
          (item) => +item.fanpageFormIntegration.id === +this.integrationId
        );
        if (data) {
          return {
            form_fb: data.fb_form_title,
            form_g: data.form_g_digital,
          };
        }
      }
      return "";
    },
  },
  methods: {
    async reprocessLead(item) {
      if (this.loadingReprocess) {
        return
      }
      if (
        item.status === "token_error" &&
        item.fanpage_status === "token_error"
      ) {
        this.$router.push(
          "/dynamicRoute/apps/lead-ads/fanpage/" + item.fanpage_id
        );
        return "";
      }
      this.loadingReprocess = item.id
      let reprocess = await LeadAdLeadServiceApi.put(
        "/reprocess-lead/" + item.id,
        {}
      );
      if (reprocess == "1") {
        this.dataLeads()
        this.$bvToast.toast("Reprocessando o lead", {
          title: "Fanpage",
          variant: "success",
          autoHideDelay: 3000,
          appendToast: true,
        });
      } else {
        this.$bvToast.toast("Falha ao reprocessar lead", {
          title: "Fanpage",
          variant: "danger",
          autoHideDelay: 3000,
          appendToast: true,
        });
      }
      this.loadingReprocess = false
    },
    async getLeadgen(leadgenData) {
      if (this.loadingLead) {
        return
      }
      this.loadingLead = leadgenData.id
      const leadgen = await LeadAdApiService.read(
        "forms/leadgen/" + leadgenData.fb_leadgen_id
      );

      if (leadgen.message_error) {
        this.$bvToast.toast(leadgen.message_error, {
          title: "Fanpage",
          variant: "danger",
          autoHideDelay: 3000,
          appendToast: true,
        });
        this.loadingLead = false
        return;
      }
      this.leadgen = Object.assign(leadgen, {
        fanpage_id: leadgenData.fanpage_id,
        fanpage_status: leadgenData.fanpage_status,
      });
      this.updatedItem = moment(leadgenData.updated_at).format("DD/MM/YYYY HH:MM")
      this.$bvModal.show(this.idModalviewLeadgen);
      this.loadingLead = false
    },
    setDataForPaginate(leads) {
      this.currentPage = leads.current_page;
      this.totalPages = leads.last_page;
    },
    async dataLeads(page) {
      this.loadingTable = true;
      if (!page && !this.numberPage) {
        this.numberPage = 1;
      } else if (page !== undefined) {
        this.numberPage = page;
      }

      this.clearIdsFilter();
      try {
        const response = await LeadAdApiService.read(
          `leadgen/${this.fanpageId}/${this.integrationId}?page=${this.numberPage}`
        );
        this.setDataForPaginate(response);
        response.data.pop();
        this.totalIntegrations = response.total;
        if (response.error) {
          this.loadingTable = false;
          this.$bvToast.toast("Falha ao buscar dados de leads", {
            title: "Fanpage",
            variant: "danger",
            autoHideDelay: 3000,
            appendToast: true,
          });
          return {};
        }

        this.formatData(response);
        this.loadingTable = false;
        return response;
      } catch (e) {
        this.$bvToast.toast("Falha ao carregar dados de leadgens", {
          title: "Fanpage",
          variant: "danger",
          autoHideDelay: 3000,
          appendToast: true,
        });
      }
      this.loadingTable = false;
    },
    clearIdsFilter() {
      if (!this.fanpageId) {
        this.fanpageId = "";
      }
      if (!this.integrationId) {
        this.integrationId = "";
      }
    },
    clearFilter() {
      let partUri = `/${this.$store.getters.user.user.tenant_subdomain}/apps/lead-ads/leadgen/`;
      this.$router.push(partUri);
    },
    formatData(leads) {
      try {
        this.items = [];
        for (let lead of leads.data) {
          this.items.push({
            id: lead.id,
            "Capturado em": moment(lead.created_at).format("DD/MM/YYYY HH:MM"),
            integration: lead.fb_form_title,
            Fanpage: lead.fb_fanpage_title,
            "Último lead cap.": lead.last_catch ?? "Aguardando leads",
            fb_form_title: lead.fb_form_title,
            form_g_digital: lead.form_g_digital,
            status: lead.status,
            fb_leadgen_id: lead.fb_leadgen_id,
            fanpage_id: lead.fanpage_id,
            fanpage_status: lead.fanpage_status,
          });
        }
      } catch (e) {
        // console.log(e);
        this.$bvToast.toast("Falha ao formatar dados do lead", {
          title: "Fanpage",
          variant: "danger",
          autoHideDelay: 3000,
          appendToast: true,
        });
      }
    },
    async dataFilterForm() {
      this.dataForms = [];
      if (this.fanpageId) {
        this.dataForms = await LeadAdApiService.read(
          `forms/to-filter/${this.fanpageId}`
        );
      }
    },
    async dataFilterFanpage() {
      this.dataFanpages = await LeadAdApiService.read(`all`);
    },
    filterForFanpage(event) {
      this.fanpageId = event.id;
      let partUri = `/${this.$store.getters.user.user.tenant_subdomain}/apps/lead-ads/${this.fanpageId}/leadgen/`;
      if (this.$route.path != partUri) {
        this.integrationId = null;
        this.dataLeads();
        this.dataFilterForm();
        this.$router.push(partUri);
      }
    },
    filterForForm(event) {
      this.integrationId = event.fanpageFormIntegration.id;
      let partUri = `/${this.$store.getters.user.user.tenant_subdomain}/apps/lead-ads/${this.fanpageId}/leadgen/${this.integrationId}`;
      if (this.$route.path != partUri) {
        this.$router.push(partUri);
        this.dataLeads();
      }
    },
  },
  mounted: async function () {
    this.fanpageId = this.$route.params.idFanpage;
    this.integrationId = this.$route.params.idIntegration;
    this.dataFilterFanpage();
    this.dataFilterForm();
    this.dataLeads(); 
  },
};
</script>

<style scoped lang="scss">
.integration-option {
  width: 240px;
  max-width: 240px;
  overflow: hidden;
  span {
    width: 80px;
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  * {
    display: inline;
  }
}
.change-color {
  filter: invert(42%) sepia(65%) saturate(6711%) hue-rotate(159deg) brightness(99%) contrast(101%);
}

.drop-item {
  max-width: 240px;
  width: 240px;
  overflow: hidden;
}

.drop-item span {
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.lead-ads-historicc {
  .total-integration {
    margin-top: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #81858e;
  }

  .status-lead {
    width: 6px;
    height: 6px;
    left: 119.7px;
    top: 307.29px;
    border-radius: 50%;
  }

  .status-success {
    background: var(--greenn);
  }

  .status-queued {
    background-color: var(--orange);
  }

  .status-error,
  .status-token_error {
    background-color: red;
  }
  img {
    padding: 5px;
  }

  .img-page {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 30px;
    width: 40px;
    min-width: 40px;
    display: inline-flex;
    margin-right: 10px;
  }
}
</style>

<style>
.lead-ads-historicc td {
  vertical-align: middle !important;
}


.lead-ads-historicc .wrap-pages{
  display: none !important;
}

.lead-ads-historicc .btn-filter-disabled > button {
  background-color: #efeded !important;
}

.lead-ads-historicc .dropdown-item.active,
.lead-ads-historicc .dropdown-item:active {
  background-color: #ede8e8;
}

.lead-ads-historicc td:first-child > div {
  margin-top: 5px;
}

.lead-ads-historicc .dropdown-menu {
  border: 0 !important;
  box-shadow: 8px 8px 8px rgba(161, 159, 159, 0.1);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
}
.lead-ads-historicc .btn-filter + ul {
  padding: 0 !important;
}
.lead-ads-historicc .btn-filter + .dropdown-menu > li > a {
  padding: 14px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  display: flex;
  align-items: center;
}

.lead-ads-historicc .link-action-view {
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.2px;
  color: #007bff;
}
.lead-ads-historicc .link-action-view:hover {
  color: #007bff;
  text-decoration: none;
}

.lead-ads-historicc .btn-filter::after {
  opacity: 0;
}

.lead-ads-historicc tr > td {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #81858e;
}

.lead-ads-historicc .btn-filter {
  height: 40px;
  background: var(--greenn2);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: var(--greenn);
  border: 0;
}

.lead-ads-historicc .btn-filter:hover {
  color: var(--greenn);
}

.lead-ads-historicc .content-table {
  margin-top: 33px;
}

.lead-ads-historicc th {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: var(--gray01);
}
/* .active {
  color: white !important;
} */
</style>
